import { PlanosPrecosStyle, Planos, Avisos } from './styles/planosPrecos.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faCheck, faXmark, faHourglassEnd, faListOl, faPlus } from '@fortawesome/free-solid-svg-icons';
import numeros from '../dados/numeros.json';
import { startCountdown } from './countdownTimer';
import { useEffect } from 'react';


const PlanosPrecos = () => {

    useEffect(() => {
        const currentDateT = new Date(); // Data para o primeiro contador
        currentDateT.setHours(24, 0, 0, 0);

        startCountdown({
            diaId: 'diat',
            hrsId: 'hrst',
            minId: 'mint',
            segId: 'segt',
            countDownDate: currentDateT.getTime() // Passe a data de término
        });
    }, []);

    useEffect(() => {
        const currentDateB = new Date(); // Data para o segundo contador
        currentDateB.setHours(24, 0, 0, 0);

        startCountdown({
            diaId: 'diab',
            hrsId: 'hrsb',
            minId: 'minb',
            segId: 'segb',
            countDownDate: currentDateB.getTime() // Passe a data de término
        });
    }, []);

    return (

        <PlanosPrecosStyle>

            <p>AGORA ESCOLHA SEM DÚVIDA</p>
            <h2>O MELHOR CONTEÚDO E, AGORA, O MENOR PREÇO</h2>
            <span><FontAwesomeIcon icon={faFileInvoiceDollar} color='#27921A' fontSize='28px' /></span>

            <Planos>


                <a href='https://sun.eduzz.com/1783031?cupom=DBBLK15891&pf=1' target='_blank' rel='noreferrer'>
                    <div className='plano'>
                        <div className='preco-circle circle-tri'>
                            <p><del>{numeros.preco_trianual}</del></p>
                            {/* <h5 className='icon'>X</h5> */}
                        </div>
                        <div className='detalhes detalhes-tri'>
                            <h3>TRI-ANUAL</h3>
                            <p className='tempo-detalhes'>TRINTA E SEIS meses + discursivas</p>
                            <div className='plano-detalhes'>
                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>36 meses</b> para estudar</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p>Acesso <b>ilimitado</b> aos cursos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Todos</b> os planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_aulas}+</b> aulas focadas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_modulos}+</b> módulos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_planos}+</b> planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Certificados</b> padrão</p>
                                </div>

                                {/*                                 <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Aulões</b> gravados e ao vivo</p>
                                </div> */}

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>01 curso</b> gravado de discursivas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>04 discursivas</b> corrigidas</p>
                                </div>

                                <span><FontAwesomeIcon icon={faHourglassEnd} color='#27921A' fontSize='22px' /></span>
                                <p className='preco'>Em até 12x de <b className='parcela'>64</b><small>,90/mês</small></p>

                                <div className='promo-banner promo-banner-tri'>
                                    {/*<span><b>+03</b> ano de bônus!</span>*/}
                                </div>

                            </div>

                            <div className='contador'>
                                <div className='circle' id='diat'></div>
                                <div className='circle' id='hrst'></div>
                                <div className='circle' id='mint'></div>
                                <div className='circle' id='segt'></div>
                            </div>

                            <h4>COMECE JÁ</h4>

                            <div className='banner-promo banner-tri'>
                                <div>
                                    <div className='titulo-promo'>Bônus do Tri-Anual!</div>
                                </div>
                                <div>
                                    <ul className='texto-promo'>
                                        <li>+<b>01</b> ano de <b>bônus</b> no Tri-Anual</li>
                                        <li>Seu Tri-Anual ficará com <b>04 ANOS</b></li>
                                        <li>Após o relógio pode haver alterações!</li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </div>

                </a>

                <a href='https://sun.eduzz.com/125117?cupom=IAOSN09982&pf=1' target='_blank' rel='noreferrer'>
                    <div className='plano'>
                        <div className='preco-circle circle-bi'>
                            <p><del>{numeros.preco_bianual}</del></p>
                            {/* <h5 className='icon'>X</h5> */}
                        </div>
                        <div className='detalhes detalhes-bi'>
                            <h3>BI-ANUAL</h3>
                            <p className='tempo-detalhes'>VINTE E QUATRO meses + discursivas</p>
                            <div className='plano-detalhes'>
                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>24 meses</b> para estudar</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p>Acesso <b>ilimitado</b> aos cursos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Todos</b> os planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_aulas}+</b> aulas focadas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_modulos}+</b> módulos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_planos}+</b> planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Certificados</b> padrão</p>
                                </div>

                                {/*                                 <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Aulões</b> gravados e ao vivo</p>
                                </div> */}

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>01 curso</b> gravado de discursivas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>04 discursivas</b> corrigidas</p>
                                </div>

                                <span><FontAwesomeIcon icon={faHourglassEnd} color='#27921A' fontSize='22px' /></span>
                                <p className='preco'>Em até 12x de <b className='parcela'>49</b><small>,00/mês</small></p>

                                <div className='promo-banner promo-banner-bi'>
                                    {/*<span><b>+01</b> ano de bônus!</span>*/}
                                </div>

                            </div>

                            <div className='contador'>
                                <div className='circle' id='diab'></div>
                                <div className='circle' id='hrsb'></div>
                                <div className='circle' id='minb'></div>
                                <div className='circle' id='segb'></div>
                            </div>

                            <h4>COMECE JÁ</h4>

                            <div className='banner-promo banner-bi'>
                                <div>
                                    <div className='titulo-promo'>Bônus do Bi-Anual!</div>
                                </div>
                                <div>
                                    <ul className='texto-promo'>
                                        <li>+<b>01</b> ano de <b>bônus</b> no Bi-Anual</li>
                                        <li>Seu Bi-Anual ficará com <b>03 ANOS</b></li>
                                        <li>Após o relógio pode haver alterações!</li>
                                    </ul>
                                </div>
                            </div>

                        </div>



                    </div>

                </a>

                <a href='https://sun.eduzz.com/125119?cupom=WOEIU18934&pf=1' target='_blank' rel='noreferrer'>
                    <div className='plano'>
                        <div className='preco-circle'>
                            <p><del>{numeros.preco_anual}</del></p>
                            {/* <h5 className='icon'>X</h5> */}
                        </div>
                        <div className='detalhes'>
                            <h3>ANUAL</h3>
                            <p className='tempo-detalhes'>DOZE meses</p>
                            <div className='plano-detalhes'>
                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>12 meses</b> para estudar</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p>Acesso <b>ilimitado</b> aos cursos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>Todos</b> os planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_aulas}+</b> aulas focadas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_modulos}+</b> módulos</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faCheck} color='#27921A' fontSize='24px' />
                                    <p><b>{numeros.numero_planos}+</b> planos de estudo</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faXmark} color='#FF0000' fontSize='24px' />
                                    <p><b>Certificados</b> padrão</p>
                                </div>

                                {/*                                 <div className='detalhe'>
                                    <FontAwesomeIcon icon={faXmark} color='#FF0000' fontSize='24px' />
                                    <p><b>Aulões</b> gravados e ao vivo</p>
                                </div> */}

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faXmark} color='#FF0000' fontSize='24px' />
                                    <p><b>01 curso</b> gravado de discursivas</p>
                                </div>

                                <div className='detalhe'>
                                    <FontAwesomeIcon icon={faXmark} color='#FF0000' fontSize='24px' />
                                    <p><b>04 discursivas</b> corrigidas </p>
                                </div>

                                <span><FontAwesomeIcon icon={faHourglassEnd} color='#27921A' fontSize='22px' /></span>
                                <p className='preco'>Em até 12x de <b className='parcela'>41</b><small>,00/mês</small></p>
                            </div>
                            <h4>COMECE JÁ</h4>                            
                        </div>
                    </div>
                </a>
            </Planos>

            <p>ATENÇÃO: LEIA AS INFORMAÇÕES ABAIXO</p>
            <h2>AVISOS IMPORTANTES...</h2>
            <span><FontAwesomeIcon icon={faListOl} color='#27921A' fontSize='24px' /></span>

            <Avisos>
                <div className='aviso'>
                    <h3>Login na Plataforma</h3>
                    <div className='color-rule'></div>

                    <input type='checkbox' id='1,1'></input>
                    <label htmlFor='1,1'><h4>1. COMO CRIO UM USUÁRIO NO NUTROR?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>O usuário será o e-mail que você cadastrou durante o processo de assinatura.</p>
                        <p>A <b>senha</b> será enviada para este <b>e-mail</b>.</p>
                        <p>Você usará o Nutror para duas coisas, essencialmente:</p>
                        <p><b>(a)</b> Assistir aos vídeos;</p>
                        <p><b>(b)</b> Gerar certificados.</p>
                    </div>

                    <input type='checkbox' id='1,2'></input>
                    <label htmlFor='1,2'><h4>2. COMO CRIO UM USUÁRIO NO PROVAS DE TI?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>Não é necessário. O Provas de TI é apenas uma "vitrine". Sua função é a de apresentar as nossas {numeros.numero_aulas} aulas agrupadas por Curso ou por Planos de Estudo. Não precisa se logar para navegar aqui.</p>
                        <p>Você usará o Provas de TI para:</p>
                        <p><b>(a)</b> Navegar pelos Planos de Estudo;</p>
                        <p><b>(b)</b> Fazer buscas rápidas por conteúdo.</p>
                    </div>

                    <input type='checkbox' id='1,3'></input>
                    <label htmlFor='1,3'><h4>3. COMO ESTUDO NO MEU DIA A DIA?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            Há várias formas. Assista ao vídeo abaixo para entender como estudar pelo Nutror. Ele também explica como buscar conteúdo,
                            como utilizar os planos de estudo e - de forma bem resumida - dá outras informações importantes.
                        </p>
                        <iframe title='video' src='https://player.vimeo.com/video/457284680' allowFullScreen ></iframe>
                    </div>
                </div>

                <div className='aviso'>
                    <h3>Sobre o Curso de Discursivas</h3>
                    <div className='color-rule'></div>

                    <input type='checkbox' id='2,1'></input>
                    <label htmlFor='2,1'><h4>1. COMO ENTRO EM CONTATO COM O PROFESSOR?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            A definição dos temas das discursivas e o agendamento das entregas são feitos <b>por e-mail</b>.
                            Os dados de acesso ao curso e envio das redações são enviados logo após a confirmação do pagamento.
                        </p>
                    </div>

                    <input type='checkbox' id='2,2'></input>
                    <label htmlFor='2,2'><h4>2. POSSO DIRECIONAR PARA A MINHA NECESSIDADE?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            Claro! Essa é a ideia. As dissertações devem ser enviadas no formato padrão de no máximo <b>30 a 40 linhas</b> (dependendo da "banca alvo" do aluno).
                            As instruções estão no curso gravado em que o aluno é matriculado automaticamente quando adquire o plano.
                        </p>
                    </div>

                    <input type='checkbox' id='2,3'></input>
                    <label htmlFor='2,3'><h4>3. QUANTO TEMPO TEREI PARA USAR?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>Combine os 04 temas e envie as 04 redações dentro do período de <b>vigência do plano</b> contratado.
                            São <b>04 redações no total</b> para usar ao longo da vigência do plano.</p>
                    </div>

                    <input type='checkbox' id='2,4'></input>
                    <label htmlFor='2,4'><h4>4. COMO É O CURSO DE DISCURSIVAS?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            É um curso gravado ao vivo com duração de 09 (<b>NOVE</b>) horas realizado pelo Prof.
                            Walter Cunha. São as gravações deste curso que são disponibilizadas no Plano para servir de
                            embasamento teórico para que o aluno possa desenvolver os seus textos.
                        </p>
                    </div>

                    <input type='checkbox' id='2,5'></input>
                    <label htmlFor='2,5'><h4>5. ESTÁ INCLUSA MENTORIA INDIVIDUAL?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            Não. Mentoria requer atenção individualizada do professor para cada aluno, o que inviabilizaria os valores acima.
                            A correção das redações é feita de forma assíncrona (sem contato agendado direto com o professor) e não há reuniões via Zoom.
                            Não há horas de mentoria listadas nos planos acima.
                        </p>
                    </div>

                    <input type='checkbox' id='2,6'></input>
                    <label htmlFor='2,6'><h4>6. ONDE POSSO ADQUIRIR UMA MENTORIA?</h4> <FontAwesomeIcon icon={faPlus} color='#6F8190' fontSize='20px' /></label>
                    <div className='resposta'>
                        <p>
                            Na página de <a href='/mentorias' target='_blank'>mentorias em andamento</a> você pode ver as mentorias que ainda estão com vagas abertas, os
                            valores específicos de cada mentoria e os descontos para quem já é assinante de algum plano do Provas de TI.
                        </p>
                    </div>

                </div>
            </Avisos>

        </PlanosPrecosStyle>
    )
}

export default PlanosPrecos;