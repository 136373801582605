import CabecalhoInfo from '../componentes/cabecalhoInfo';
import CabecalhoMenu from '../componentes/cabecalhoMenu';
import Rodape from '../componentes/rodape';
import TituloPagina from '../componentes/tituloPagina';
import BannerNumeros from '../componentes/bannerNumeros';
import DepoimentosCarrossel from '../componentes/depoimentosCarrossel';
import PlanosPrecos from '../componentes/planosPrecos';
import CarrosselProfessores from '../componentes/carrosselProfessores';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion, faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { PlanosDeAssinaturaStyle, FraseCardInformativo } from './styles/PlanosDeAssinatura.style';
import numeros from '../dados/numeros.json';
import IrAoTopoWidget from '../componentes/irAoTopoWidget';
import SeoHelmet from '../componentes/seoHelmet';

const PlanosDeAssinatura = () => {
    return (
            <PlanosDeAssinaturaStyle>
            <SeoHelmet 
                conector=' - '
                subtitulo='Como Assinar'
                linkcanonical='planosdeassinatura'
            />
            <CabecalhoInfo />
            <CabecalhoMenu />
            <TituloPagina h1ClassName='center'
                          titulo='NOSSO FOCO É TI PARA CONCURSOS'                          
                          info='Escolha uma assinatura e acesse todos os planos de estudo para concursos de TI.'                          
                          link='Nossos Planos'
                          hiddenClassName='hidden'
                          getStyle={() => {}}
            />
            <div className='porque-provas'>
                <p className='titulo'>Por que <b>PROVAS DE TI</b> ?</p>
                <p>
                    Desde a lista de e-mails TIMasters (criada em <b>Jul/2007</b> pelo Prof. Walter Cunha), lá se vão
                    vários anos. Muitos professores da atualidade foram nossos alunos no passado. Qualquer que seja o
                    site que você conhece, nenhum está há tanto tempo quanto nós gerando materiais de qualidade para
                    Concursos de TI. Ninguém tem tanto material específico quanto nós. Quando ninguém mais se interessava
                    por criar materiais das disciplinas mais improváveis, já estávamos aqui, preparando os melhores conteúdos
                    para você.
                </p>
                <p>
                    Nesta segunda década de existência, vamos desenvolver cada vez mais uma plataforma que torne o conteúdo
                    mais acessível a todos. Em 2019 lançamos um novo modelo voltado para o acesso através de assinaturas.
                    Assim, um conteúdo que antes só estava acessível por mais de R$ 5.000,00, agora pode ser assinado por um
                    décimo desse valor... É isso mesmo!
                </p>
                <p><b>Em uma única assinatura, você tem acesso a todos os Planos de Estudo!</b></p>
                <p>Aproveite!</p>
            </div>
            <BannerNumeros background={false}
                           info={false} 
            />
            <div className='videos'>
                <p>SE VOCÊ TEM ALGUMA DÚVIDA...</p>
                <p className='ajuda' >OS VÍDEOS ABAIXO VÃO AJUDAR VOCÊ</p>
                <span><FontAwesomeIcon icon={faQuestion} color='#27921A' fontSize='30px' /></span>

                <div className='video-players'>
                    <iframe title='video' src="https://player.vimeo.com/video/391814903" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                    <iframe title='video' src="https://player.vimeo.com/video/391815029" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>

                    <iframe title='video' src="https://player.vimeo.com/video/391815124" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                    <iframe title='video' src="https://player.vimeo.com/video/391815253" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>

                    <iframe title='video' src="https://player.vimeo.com/video/391815320" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                    <iframe title='video' src="https://player.vimeo.com/video/457284680" width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>
                </div>

                <FraseCardInformativo>
                    <div className='frase-wrapper'>
                        <FontAwesomeIcon icon={faQuoteLeft} className='quote-icon'/>
                        <h3 className='frase'>
                            O novo formato reduziu em 80% o preço final!
                        </h3>
                        <p className='txt'>
                            "Por mais de 10 anos nossa política foi a de cobrar por módulos. Mas, com mais de {numeros.numero_modulos} módulos
                            (mais de {numeros.numero_aulas} aulas), é necessário implementar um meio para tornar o conteúdo acessível a todos.
                            No formato atual, você assina um plano e tem acesso a todo o conteúdo. Ou seja, acesso a milhares
                            de aulas pagando muito menos. E nós conseguimos atingir cada vez mais alunos, com baixo custo
                            adicional... Bons estudos"
                        </p>
                        <p className='autor-info'>Prof. Luis Claudio</p>
                    </div>
                </FraseCardInformativo>
            </div>

            <DepoimentosCarrossel />

            <PlanosPrecos />

            <CarrosselProfessores />

            <Rodape />

            <IrAoTopoWidget />
            
        </PlanosDeAssinaturaStyle>
    )
}

export default PlanosDeAssinatura;