import styled from "styled-components";

export const PlanosPrecosStyle = styled.div`
  padding: 50px 0 80px 0;
  text-align: center;
  width: 1144px;
  margin: auto;

  p {
    font-size: 17px;
    color: #49575f;
  }

  h2 {
    font-size: 30px;
    font-weight: 900;
    margin: 0 0 20px 0;
    color: #49575f;
  }

  span {
    &:before {
      content: "";
      display: inline-block;
      margin: 0 15px 8px 0;
      height: 2px;
      width: 20px;
      background-color: #27921a;
    }

    &:after {
      content: "";
      display: inline-block;
      margin: 0 0 8px 15px;
      height: 2px;
      width: 20px;
      background-color: #27921a;
    }
  }

  @media (max-width: 1200px) {
    margin: 30px 10% 60px 10%;
    width: auto;
  }

  @media (max-width: 1000px) {
    margin: 20px 5% 50px 5%;
  }
`;

export const Planos = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 50px auto 80px auto;

  a {
    text-decoration: none;
    color: #000;
  }

  .plano {
    width: 350px;
    margin: 10px 15px;

    &:hover {
      cursor: pointer;

      .detalhes {
        background-color: #27921a;
        transform: scale(1.03);
        box-shadow: 0 8px 15px rgb(0 0 0 / 15%);

        h3 {
          color: #fff;
        }

        .tempo-detalhes {
          color: #fff;
        }

        h4 {
          color: #fff;
        }
      }

      .detalhes-tri {
        background-color: #2a7222;
      }
      .detalhes-bi {
        background-color: #2a7222;
      }
    }
  }

  .preco-circle {
    width: 170px;
    height: 150px;
    border-radius: 50%;
    background-color: #eaedf5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 10px solid #fff;
    margin: auto;
    z-index: 3;

    p del {
      font-size: 48px;
      font-weight: 800;
      color: #242c42;
      text-decoration: line-through red 6px;

      &:before {
        content: "R$";
        position: absolute;
        font-size: 15px;
        font-weight: 500;
        top: 40px;
      }
    }

    .icon {
      position: absolute;
      bottom: -120px;
      font-size: 85px;
      color: red;
      font-weight: 400;
    }
  }

  .circle-tri {
    background-color: #eaedf5;
  }

  .circle-bi {
    background-color: #eaedf5;
  }

  .detalhes {
    background-color: #eaedf5;
    width: 100%;
    padding: 50px 0 5px 0;
    position: relative;
    bottom: 30px;
    transition: all 0.5s;

    h3 {
      font-size: 30px;
      font-weight: 500;
      color: #49575f;
      margin: 0;
    }

    .tempo-detalhes {
      font-size: 15px;
      margin: 5px 0 30px 0;
    }
  }

  .plano-detalhes {
    background-color: #fff;
    width: 92%;
    margin: auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 20px 0 10px 0;

    p {
      margin-left: 10px;
      font-size: 16px;
      color: #8c9aa5;
    }

    b {
      color: #49575f;
    }

    .preco {
      margin: 5px 0 20px 0;
    }

    .parcela {
      font-size: 45px;
      color: #27921a;
    }

    small {
      color: #27921a;
      font-weight: 600;
    }

    .detalhe {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: 25px;
    }

    span {
      margin: 30px 0 10px 0;

      &:before {
        content: "";
        display: inline-block;
        margin: 0 5px 8px 0;
        height: 1px;
        width: 5px;
        background-color: #27921a;
      }

      &:after {
        content: "";
        display: inline-block;
        margin: 0 0 8px 5px;
        height: 1px;
        width: 5px;
        background-color: #27921a;
      }
    }

    .promo-banner {
      background-color: white;
      background-size: 100% auto;
      opacity: 0.3;
      color: black;
      border: 2px solid #27921a;
      border-radius: 10px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 24px;
      transition-duration: 0.4s;
      cursor: pointer;

      &:hover {
        background-color: #27921a;
        color: black;
        font-weight: 700;
        font-size: 20px;
        opacity: 1;
      }

      b {
        color: #ffffff;
        font-weight: 800;
      }
      a {
        color: #ffffff;
        font-weight: 700;
      }
      span {
        color: white;
        font-weight: 700;
      }
    }

    .promo-banner-tri {
      background-image: url("../../../assets/images/promos/megaplanos.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 46px 32px;
    }

    .promo-banner-bi {
      background-image: url("../../../assets/images/promos/megaplanos.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 46px 32px;
    }
  }

  h4 {
    font-weight: 800;
  }

  .select {
    .detalhes {
      background-color: #27921a;
      transform: scale(1.03);
      box-shadow: 0 8px 15px rgb(0 0 0 / 15%);

      h3 {
        color: #fff;
      }

      .tempo-detalhes {
        color: #fff;
      }

      h4 {
        color: #fff;
      }
    }
  }

  .contador {
    font-size: 24px;
    color: #ffffff;
    margin-top: 14px;

    .circle {
      display: inline-block;
      width: 36px;
      height: 36px;
      background: black;
      -moz-border-radius: 70px;
      -webkit-border-radius: 70px;
      border-radius: 50%;
      margin: 0 2px;
    }

    #diat {
      &:after {
        content: "dia";
        display: inline-block;
        font-size: 12px;
      }
    }
    #diab {
      &:after {
        content: "dia";
        display: inline-block;
        font-size: 12px;
      }
    }
    #hrst {
      &:after {
        content: "hrs";
        display: inline-block;
        font-size: 12px;
      }
    }
    #hrsb {
      &:after {
        content: "hrs";
        display: inline-block;
        font-size: 12px;
      }
    }
    #mint {
      &:after {
        content: "min";
        display: inline-block;
        font-size: 12px;
      }
    }
    #minb {
      &:after {
        content: "min";
        display: inline-block;
        font-size: 12px;
      }
    }
    #segt {
      &:after {
        content: "seg";
        display: inline-block;
        font-size: 12px;
      }
    }
    #segb {
      &:after {
        content: "seg";
        display: inline-block;
        font-size: 12px;
      }
    }
  }

  .banner-promo {
    width: 100%;
    height: 130px;
    background-color: #2a7222;
    max-width: 336px;
    border-radius: 10px;
    margin: 24px 4px 12px 6px;

    .titulo-promo {
      text-align: center;
      color: #ffffff;
      font-weight: 700;
      padding: 12px 0 0 0;
    }

    .subtitulo-promo {
      text-align: center;
      color: #ffffff;
      font-weight: 700;
      padding: 0 0 0 0;
    }

    .texto-promo {
      text-align: left;
      color: #ffffff;
      margin: 14px 1px 0 -10px;
    }
  }

  .banner-tri {
    background-color: #2a7222;
  }
  .banner-bi {
    background-color: #2a7222;
  }
`;

export const Avisos = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .aviso {
    width: 470px;
    text-align: justify;
    margin: 15px 50px;

    h3 {
      font-size: 30px;
      color: #49575f;
      margin: 50px 0 5px 0;
    }

    .color-rule {
      height: 5px;
      width: 50px;
      background-color: #27921a;
      margin-bottom: 30px;
    }

    input {
      position: absolute;
      opacity: 0;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      padding: 2% 5%;
      margin: 4px 0;
      background-color: #fafafa;

      &:hover {
        cursor: pointer;

        h4 {
          text-decoration: underline #6f8190;
        }
      }
    }

    input:checked {
      + label + .resposta {
        max-height: 100vh;
        padding: 3% 5% 8% 5%;
        box-shadow: 0 0 20px rgb(0 0 0 / 10%);
      }

      + label {
        background-color: #fff;
        box-shadow: 0 0 20px rgb(0 0 0 / 10%);
      }
    }

    h4 {
      margin: 1% 3% 1% 0;
      color: #6f8190;
      font-size: 16px;
      font-weight: 400;
    }

    p {
      margin: 0;
      font-size: 14px;
      color: #333;
    }

    iframe {
      margin-top: 15px;
      width: 350px;
      height: 180px;
    }

    .resposta {
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s;
    }

    input:checked {
      + label + .resposta {
        max-height: 100vh;
        padding: 3% 5% 8% 5%;
      }
    }

    @media (max-width: 1200px) {
      margin: 0;

      iframe {
        margin-top: 15px;
        width: 250px;
        height: 128px;
      }
    }
  }
`;
